import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { ReactSVG } from "react-svg"

import FlexSection from "../reusable-components/layout/flex-section"

import { getProperty } from "../../lib/utils"

const OurStoryTimeline = () => {
  const aboutUsPageQueryData = useStaticQuery(ABOUT_US_PAGE_QUERY)
  const ourStoryTitle = getProperty(
    aboutUsPageQueryData,
    ["wpPage", "acf_about_us", "ourStoryTitle"],
    ""
  )
  const ourStorySubTitle = getProperty(
    aboutUsPageQueryData,
    ["wpPage", "acf_about_us", "ourStorySubTitle"],
    ""
  )
  const ourStoryTimeline = getProperty(
    aboutUsPageQueryData,
    ["wpPage", "acf_about_us", "ourStoryTimeline"],
    []
  )

  const ourStoryCustomCSSHeight = "24vh"
  const ourStoryCustomCSSWidth = "6vw"

  return (
    <StyledFlexSection>
      <h2>{ourStoryTitle}</h2>
      <h3>{ourStorySubTitle}</h3>
      {ourStoryTimeline.map((item, index) => {
        const itemImage = getProperty(
          item,
          ["ourStoryTimelineItemImage", "localFile", "publicURL"],
          ""
        )
        return (
          <OurStoryStyledTimeLineSection
            key={index}
            ourStoryCustomCSSHeight={ourStoryCustomCSSHeight}
            margin={"0 auto 0"}
            justify={"center"}
            align={"center"}
          >
            <TimeLineStructureStyling
              ourStoryCustomCSSWidth={ourStoryCustomCSSWidth}
            >
              <div className={"line"} />
              <div className={"circle"} />
            </TimeLineStructureStyling>
            <SvgContainerStyling
              ourStoryCustomCSSHeight={ourStoryCustomCSSHeight}
            >
              <ReactSVG src={itemImage} />
            </SvgContainerStyling>
            <TimeLineContentStyling
              ourStoryCustomCSSHeight={ourStoryCustomCSSHeight}
              ourStoryCustomCSSWidth={ourStoryCustomCSSWidth}
              dangerouslySetInnerHTML={{
                __html: item.ourStoryTimelineItemContent,
              }}
            />
          </OurStoryStyledTimeLineSection>
        )
      })}
    </StyledFlexSection>
  )
}

export default OurStoryTimeline

// ===============
//     STYLES
// ===============
const StyledFlexSection = styled(FlexSection)`
  h3 {
    text-transform: none;
  }
`
const OurStoryStyledTimeLineSection = styled(FlexSection)`
  flex-wrap: wrap;
  &:first-of-type {
    margin-top: 3rem;
  }
  height: calc(
    ${({ ourStoryCustomCSSHeight }) => ourStoryCustomCSSHeight} + 2.5vh
  );
  position: relative;
  p:first-child {
    margin-top: 0;
  }
  &:first-of-type .circle,
  &:last-child .circle {
    border: 2px solid ${({ theme }) => theme.colors.blue};
    border-radius: 100%;
    background: white;
  }
  & .circle,
  & .circle {
    width: 25px;
    height: 25px;
    background: ${({ theme }) => theme.colors.yellow};
    border-radius: 100%;
    border: 5px solid white;
    z-index: 5;
  }
  &:first-of-type .line {
    top: calc(50% + 18px);
    height: calc(
      calc(${({ ourStoryCustomCSSHeight }) => ourStoryCustomCSSHeight} + 2.5vh) /
        2
    );
  }
  &:last-child .line {
    bottom: calc(50% + 18px);
    height: calc(
      calc(${({ ourStoryCustomCSSHeight }) => ourStoryCustomCSSHeight} + 2.5vh) /
        2
    );
  }
  & .line,
  & .line {
    position: absolute;
    width: 1px;
    height: calc(
      ${({ ourStoryCustomCSSHeight }) => ourStoryCustomCSSHeight} + 2.5vh
    );
    background: ${({ theme }) => theme.colors.blue};
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
    margin-bottom: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    flex-direction: column;
    height: 100%;
    margin-top: 3rem;
  }
`
const SvgContainerStyling = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.lightgrey1};
  width: ${({ ourStoryCustomCSSHeight }) => ourStoryCustomCSSHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ ourStoryCustomCSSHeight }) => ourStoryCustomCSSHeight};
  svg {
    height: 5em;
    width: 5em;
  }
`

const TimeLineContentStyling = styled.div`
  width: calc(
    100% -
      calc(
        ${({ ourStoryCustomCSSWidth }) => ourStoryCustomCSSWidth} +
          ${({ ourStoryCustomCSSHeight }) => ourStoryCustomCSSHeight}
      )
  );
  padding-left: 2.5vw;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    pading-left: 0;
    padding-top: 2rem;
  }
`

const TimeLineStructureStyling = styled.div`
  width: ${({ ourStoryCustomCSSWidth }) => ourStoryCustomCSSWidth};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
  }
`
// ===============
//      QUERY
// ===============
const ABOUT_US_PAGE_QUERY = graphql`
  {
    wpPage(slug: { eq: "about" }) {
      acf_about_us {
        ourStoryTitle
        ourStorySubTitle
        ourStoryTimeline {
          ourStoryTimelineItemContent
          ourStoryTimelineItemImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
