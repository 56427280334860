import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import CloudflareIage from "../reusable-components/cloudflare-image"

import FlexSection from "../reusable-components/layout/flex-section"
import LinkButton from "../reusable-components/buttons/link-button"

import { getProperty } from "../../lib/utils"

const WhoWeAre = () => {
  const aboutUsPageQueryData = useStaticQuery(ABOUT_US_PAGE_QUERY)

  const whoWeAreTitle = getProperty(
    aboutUsPageQueryData,
    ["wpPage", "acf_about_us", "whoWeAreTitle"],
    ""
  )
  const whoWeAreSubTitle = getProperty(
    aboutUsPageQueryData,
    ["wpPage", "acf_about_us", "whoWeAreSubTitle"],
    ""
  )
  const whoWeAreBody = getProperty(
    aboutUsPageQueryData,
    ["wpPage", "acf_about_us", "whoWeAreBody"],
    ""
  )
  const whoWeAreImage = getProperty(
    aboutUsPageQueryData,
    ["wpPage", "acf_about_us", "whoWeAreImage", "sourceUrl"],
    false
  )

  return (
    <FlexSection direction={"row"} justify={"space-between"}>
      <StyledImg
        className={"full-width-inner-section"}
        imgStyle={{
          width: "600px",
          objectFit: "cover",
          objectPosition: "0 50%",
        }}
        src={whoWeAreImage}
      />
      <WhoWeAreSectionStyling className={"full-width-inner-section"}>
        <h2>{whoWeAreTitle}</h2>
        <h3>{whoWeAreSubTitle}</h3>
        <WhoWeAreContentStyling
          dangerouslySetInnerHTML={{ __html: whoWeAreBody }}
        />
        <LinkButton
          className="button-class"
          text="Meet our team"
          to="/about-us/our-team/"
        />
      </WhoWeAreSectionStyling>
    </FlexSection>
  )
}

export default WhoWeAre

// ===============
//     STYLES
// ===============

const StyledImg = styled(CloudflareIage)`
  width: 48.3%;
  align-self: stretch;
  img {
    left: unset;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin-bottom: 3rem;
  }
`
const WhoWeAreSectionStyling = styled.div`
  width: 48.3%;
  a {
    width: 100%;
  }
  h3 {
    text-transform: none;
  }
`
const WhoWeAreContentStyling = styled.div`
  padding-bottom: 50px;
`

// ===============
//      QUERY
// ===============
const ABOUT_US_PAGE_QUERY = graphql`
  {
    wpPage(slug: { eq: "about" }) {
      acf_about_us {
        whoWeAreTitle
        whoWeAreSubTitle
        whoWeAreBody
        whoWeAreImage {
          sourceUrl
        }
      }
    }
  }
`
