import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { ReactSVG } from "react-svg"

import FlexSection from "../reusable-components/layout/flex-section"

import { getProperty } from "../../lib/utils"

const OurValues = () => {
  const aboutUsPageQueryData = useStaticQuery(ABOUT_US_PAGE_QUERY)
  const valuesTitle = getProperty(
    aboutUsPageQueryData,
    ["wpPage", "acf_about_us", "valuesTitle"],
    ""
  )
  const valuesSubTitle = getProperty(
    aboutUsPageQueryData,
    ["wpPage", "acf_about_us", "valuesSubTitle"],
    ""
  )
  const valueIcons = getProperty(
    aboutUsPageQueryData,
    ["wpPage", "acf_about_us", "valueIcons"],
    []
  )

  return (
    <FlexSection align={"center"}>
      <h2>{valuesTitle}</h2>
      <StyledH3>{valuesSubTitle}</StyledH3>
      <StyledFlexSection
        direction={"row"}
        justify={"space-around"}
        margin={"3rem auto 0"}
        width={"70%"}
        align={"center"}
      >
        {valueIcons.map((item, index) => {
          const itemImage = getProperty(
            item,
            ["valueIconImage", "localFile", "publicURL"],
            ""
          )
          return (
            <StyledFlexSection
              key={index}
              justify={"center"}
              align={"center"}
              margin={"3rem auto 0"}
            >
              <StyledReactSVGOurValues src={itemImage} />
              <strong>{item.valueIconText}</strong>
            </StyledFlexSection>
          )
        })}
      </StyledFlexSection>
    </FlexSection>
  )
}

export default OurValues

// ===============
//     STYLES
// ===============
const StyledH3 = styled.h3`
  text-transform: none;
`
const StyledReactSVGOurValues = styled(ReactSVG)`
  svg {
    height: 5em;
    width: 5em;
  }
  svg #Group_65 path,
  svg #Group_64 path,
  svg #Group_56 path,
  svg #Group_58 path:first-child,
  svg #Group_60 path {
    fill: ${({ theme }) => theme.colors.black1};
  }
`
const StyledFlexSection = styled(FlexSection)`
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    & {
      margin: 0 0 0;
    }
    & & {
      margin: 3rem 0 0;
    }
  }
`
// ===============
//      QUERY
// ===============
const ABOUT_US_PAGE_QUERY = graphql`
  {
    wpPage(slug: { eq: "about" }) {
      acf_about_us {
        valuesTitle
        valuesSubTitle
        valueIcons {
          valueIconText
          valueIconImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
